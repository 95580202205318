import "components/reports/SchoolTestReport.css";
import CustomBoxplotChart from "../ui/CustomBoxplotChart";
import CustomHeatmap from "../ui/CustomHeatmap";
import Cover from "./components/Cover";
import {heatMapData} from "../../utils/functions";
import React, {useRef} from "react";
import ScrollBar from "../ui/ScrollBar";
import CustomTableBlack from "../ui/CustomTableBlack";
import TableTooltip from "./components/TableTooltip";
import CustomButton from "../ui/CustomButton";

const Report = ({data}: any) => {
    const ref = useRef<null | HTMLDivElement>(null);
    function scrollToIndex(index: number) {
        const listNode = ref.current;
        // This line assumes a particular DOM structure:
        const imgNode = listNode?.querySelectorAll('h1')[index];

        imgNode?.scrollIntoView();
    }

    return (
        <>
        <div className="cover-bg" id="pdf">
            <div id="print_page" className="wrapper-cover">
                <Cover startDate={data.start_date} endDate={data.end_date} subject={data.subject} tag={data.tag}/>
                <div className="mb-100"></div>
                <h2 className='mb-6'>Cuprins</h2>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(0)} sxExtra={{marginBottom: "10px"}}>SECȚIUNEA 1: Surse ale datelor / participarea la testare</CustomButton>
                    <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(1)} sxExtra={{marginBottom: "10px"}}>SECȚIUNEA 2: Privire de ansamblu asupra rezultatelor</CustomButton>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px"}}>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(2)} sxExtra={{marginBottom: "10px"}}>2.1. Scoruri medii și distribuții în interiorul școlii</CustomButton>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(3)} sxExtra={{marginBottom: "10px"}}>2.2. Distribuția elevilor în categorii decile naționale</CustomButton>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(4)} sxExtra={{marginBottom: "10px"}}>2.3. Distribuția elevilor în categorii de risc</CustomButton>
                    </div>
                    <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(5)} sxExtra={{marginBottom: "10px"}}>SECȚIUNEA 3. Rezultate privind competențele măsurate, pentru fiecare clasă</CustomButton>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px"}}>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex(6)} sxExtra={{marginBottom: "10px"}}>3.1. Scoruri medii și distribuții pentru fiecare clasa</CustomButton>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px"}}>
                            {data.payload.table6.slice().reverse().map((table6: any, index: number) => (
                                <CustomButton key={index} size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex((7 + index))} sxExtra={{marginBottom: "10px"}}>3.1.{index + 1}. Scoruri medii pentru clasa {table6[index].grade}</CustomButton>
                            ))}
                        </div>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex((7 + data.payload.table6.length))} sxExtra={{marginBottom: "10px"}}>3.2. Distribuția elevilor în categorii decile naționale pentru fiecare clasa</CustomButton>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px"}}>
                            {data.payload.table7.slice().reverse().map((table7: any, index: number) => (
                                <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex((7 + data.payload.table6.length + index + 1))} sxExtra={{marginBottom: "10px"}}>3.2.{index + 1}. Distribuția elevilor în categorii decile naționale pentru clasa {table7[index].grade}</CustomButton>
                            ))}
                        </div>
                        <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex((7 + data.payload.table6.length + data.payload.table7.length + 1))} sxExtra={{marginBottom: "10px"}}>3.3. Distribuția elevilor în categorii de risc pentru fiecare clasa</CustomButton>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px"}}>
                            {data.payload.table9.slice().reverse().map((table9: any, index: number) => (
                                <CustomButton size="large" buttonType="text" bgcolor="text" onClick={() => scrollToIndex((7 + data.payload.table6.length + data.payload.table7.length + index + 2))} sxExtra={{marginBottom: "10px"}}>3.3.{index + 1}. Distribuția elevilor în categorii de risc pentru clasa {table9[index].grade}</CustomButton>
                            ))}
                        </div>
                    </div>

                </div>
                <div className="mb-13"></div>
                <div ref={ref}>
                    <h1 style={{paddingTop: 50}}>SECȚIUNEA 1: Surse ale datelor / participarea la testare</h1>
                    <div className="section-despre">
                        <div className="table-subsection">
                            <p className="table-title">Tabelul 1. Distribuția elevilor pe clase, obținută în urma testării</p>
                            <CustomTableBlack data={
                                {
                                    columns: [
                                        {
                                            id: "name",
                                            label: "Clasa",
                                        },
                                        {
                                            id: "count",
                                            label: data.subject,
                                            numeric: true,
                                        },
                                    ],
                                    rows: data.payload.table2,
                                    footer: [
                                        {
                                            name: "Total",
                                            count: data.payload.finished_tests_count,
                                        },
                                    ],
                                }
                            } />
                        </div>
                    </div>
                    <div className="mb-100"></div>
                    <h1>SECȚIUNEA 2: Privire de ansamblu asupra rezultatelor</h1>
                    <div className="section-despre">
                        <div className="subsection mb-13">
                            <p>
                                Această secțiune prezintă două feluri de informații:
                            </p>
                            <ol>
                                <li>Scoruri medii și distribuții în interiorul școlii: pentru fiecare clasă și materie (și pentru fiecare dimensiune a materiei), un scor mediu al școlii, precum și detalii privind scorurile minime și maxime și limitele pentru cvartile - scorurile în care se încadreaza 25% și respectiv 75% dintre copiii acestei școli,</li>
                                <li>Distribuții în decile naționale: pentru fiecare materie și clasă, distribuția elevilor în categorii decile la nivel național.</li>
                                <li>Distribuții în categorii de risc vs. performanță: pentru fiecare materie și clasă, distribuția elevilor în categorii de risc și performanță la nivel național, (cele 4 mari zone de funcționare).</li>
                            </ol>
                        </div>
                        <h1>2.1. Scoruri medii și distribuții în interiorul școlii</h1>
                        <div className="table-subsection">
                            <p className="table-title">Tabelul 2. Scorurile totale obtinute pentru {data.subject}, pentru fiecare clasa <TableTooltip type="totalScore"/></p>
                            <CustomTableBlack data={
                                {
                                    columns: [
                                        {label: "Clasa", id: "grade"},
                                        {label: "Dimensiune", id: "dimensions"},
                                        {label: "Numar elevi", id: "students", numeric: true,},
                                        {label: "Scor mediu", id: "avg", numeric: true,},
                                        {label: "Min", id: "min", numeric: true,},
                                        {label: "Q1 (25%)", id: "q1", numeric: true,},
                                        {label: "Q2 (50%)", id: "q2", numeric: true,},
                                        {label: "Q3 (75%)", id: "q3", numeric: true,},
                                        {label: "Max", id: "max", numeric: true,},
                                    ],
                                    rows: data.payload.table4.map((item: any) => {
                                        return {
                                            dimensions : `Scor total clasa ${item.grade}`,
                                            ...item
                                        }
                                    }),
                                }
                            } />
                        </div>
                        <div className="table-subsection mb-13">
                            <p className="table-title">Graficul 1. Boxplot cu distributia cvartilelor de performanta pentru {data.subject}, scor total
                                <TableTooltip type="boxplot"/>
                            </p>
                            <ScrollBar forceVisible="x" autoHide={false}  >
                                <div id="graph1" style={{minWidth: "600px", maxWidth: "762px"}}>
                                    <CustomBoxplotChart
                                        series={[
                                            {
                                                name: "",
                                                data: data.payload.table4.map((data: any) => {
                                                    return [data.min, data.q1, data.q2, data.q3, data.max]})
                                            }
                                        ]}
                                        xAxis={{
                                            categories:
                                                data.payload.table4.map((data: any) => {
                                                    return `Scor total clasa ${data.grade}`
                                                }),
                                            title: {
                                                text: 'Dimensiuni ale programei'
                                            }
                                        }}
                                    />
                                </div>
                            </ScrollBar>
                        </div>
                        <h1>2.2. Distribuția elevilor în categorii decile naționale</h1>
                        <div className="table-subsection">
                            <p className="table-title">Tabelul 3. Numarul de elevi din scoala plasati in fiecare decila nationala, pentru {data.subject}, pentru fiecare clasa
                                <TableTooltip type="decile"/>
                            </p>
                            <CustomTableBlack data={
                                {
                                    columns: [
                                        {label: "Clasa", id: "grade"},
                                        {label: "Dimensiune", id: "dimensions"},
                                        {label: "Decila 1 [0,10]", id: "d1", numeric: true},
                                        {label: "Decila 2 (10,20]", id: "d2", numeric: true},
                                        {label: "Decila 3 (20,30]", id: "d3", numeric: true},
                                        {label: "Decila 4 (30,40]", id: "d4", numeric: true},
                                        {label: "Decila 5 (40,50]", id: "d5", numeric: true},
                                        {label: "Decila 6 (50,60]", id: "d6", numeric: true},
                                        {label: "Decila 7 (60,70]", id: "d7", numeric: true},
                                        {label: "Decila 8 (70,80]", id: "d8", numeric: true},
                                        {label: "Decila 9 (80,90]", id: "d9", numeric: true},
                                        {label: "Decila 10 (90,100]", id: "d10", numeric: true},
                                    ],
                                    rows: data.payload.table5.map((item: any) => {
                                        return {
                                            dimensions : `Scor total clasa ${item.grade}`,
                                            ...item
                                        }
                                    }),
                                }
                            } />
                        </div>
                        <div className="table-subsection mb-13">
                            <p className="table-title">Graficul 2. Heatmap cu distributia decilelor de performanta pentru {data.subject}
                                <TableTooltip type="heatmap"/>
                            </p>
                            <ScrollBar forceVisible="x" autoHide={false}  >
                                <div id="graph2" style={{width: "762px"}}>
                                    <CustomHeatmap
                                        series={[
                                            {
                                                name: '',
                                                borderWidth: 0.2,
                                                data: heatMapData(data.payload.table5),
                                                borderColor: '#888888',
                                            },
                                        ]}
                                        yAxis={{
                                            labels: {
                                                allowOverlap: true,
                                                style: {
                                                    wordBreak: 'break-all',
                                                    textOverflow: 'allow',
                                                    width: 150
                                                }
                                            },
                                            categories:data.payload.table5.map((data: any) => {
                                                return `Scor total clasa ${data.grade}`
                                            }),
                                            title: {
                                                text: 'Dimensiuni ale programei'
                                            },
                                            reversed: true,
                                        }}
                                        xAxis={{
                                            categories: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10'],
                                            title: {
                                                text: 'Decile'
                                            }
                                        }}
                                        colorAxis={{
                                            min: 0,
                                            minColor: '#f2f9f4',
                                            maxColor: '#008D2C',
                                        }}
                                    />
                                </div>
                            </ScrollBar>
                        </div>
                        <h1>2.3. Distribuția elevilor în categorii de risc</h1>
                        <div className="table-subsection">
                            <p className="table-title">Tabelul 4. Distribuția în funcție de scorurile generale pentru materia claselor testate
                                <TableTooltip type="risc"/>
                            </p>
                            <CustomTableBlack
                                data={{
                                    columns: [
                                        {label: "Clasa", id: "grade"},
                                        {label: "Dimensiune", id: "dimensions"},
                                        {label: "A  ", id: "a", numeric: true},
                                        {label: "B  ", id: "b", numeric: true},
                                        {label: "C  ", id: "c", numeric: true},
                                        {label: "D  ", id: "d", numeric: true},
                                        {label: "E  ", id: "e", numeric: true},
                                        {label: "TOT  ", id: "total", numeric: true},
                                        {label: "A%  ", id: "a%", numeric: true},
                                        {label: "B%  ", id: "b%", numeric: true},
                                        {label: "C%  ", id: "c%", numeric: true},
                                        {label: "D%  ", id: "d%", numeric: true},
                                        {label: "E%  ", id: "e%", numeric: true},
                                    ],
                                    rows: data.payload.table8.map((item: any) => {
                                        return {
                                            dimensions : `Scor total clasa ${item.grade}`,
                                            ...item
                                        }
                                    }),
                                }}
                                extraPadding="6px 12px"
                            />
                        </div>
                        <div className="table-subsection">
                            <p className="table-title">Graficul 3. Heatmap al distribuției în funcție de scorurile generale pentru materia claselor testate
                                <TableTooltip type="riscHeatmap"/>
                            </p>
                            <ScrollBar forceVisible="x" autoHide={false}  >
                                <div id="graph3" style={{width: "762px"}}>
                                    <CustomHeatmap
                                        series={[
                                            {
                                                name: '',
                                                borderWidth: 0.2,
                                                borderColor: "#888888",
                                                data: heatMapData(data.payload.table8, "risc"),
                                            },
                                        ]}
                                        yAxis={{
                                            labels: {
                                                allowOverlap: true,
                                                style: {
                                                    wordBreak: 'break-all',
                                                    textOverflow: 'allow',
                                                    width: 150
                                                }
                                            },
                                            categories:data.payload.table8.map((data: any) => {
                                                return `Scor total clasa ${data.grade}`
                                            }),
                                            title: {
                                                text: 'Dimensiuni ale programei'
                                            },
                                            reversed: true,
                                        }}
                                        xAxis={{
                                            categories: ['A', 'B', 'C', 'D', 'E'],
                                            title: {
                                                text: 'Categorii de risc'
                                            }
                                        }}
                                        colorAxis={{
                                            min: 0,
                                            minColor: '#f2f7ff',
                                            maxColor: '#0064FF',
                                        }}
                                    />
                                </div>
                            </ScrollBar>
                        </div>
                    </div>
                    <div className="mb-100"></div>
                    <h1 className="mb-13" id="section3">SECȚIUNEA 3. Rezultate privind competențele măsurate, pentru fiecare clasă</h1>
                    <div className="section-despre">
                        <h1>3.1. Scoruri medii și distribuții pentru fiecare clasa</h1>
                        {data.payload.table6.slice().reverse().map((table6: any, index: number) => (
                            <div key={index}>
                                <h1 className='subtitleH1 mb-13'>3.1.{index + 1}. Scoruri medii pentru clasa {table6[index].grade}</h1>
                                <div className="table-subsection">
                                    <p className="table-title">Tabelul 5.{index + 1}. Scorurile totale obtinute pentru {data.subject}, clasa {table6[index].grade}
                                        <TableTooltip type="totalScore"/>
                                    </p>
                                    <CustomTableBlack data={
                                        {
                                            columns: [
                                                {label: "Dimensiune", id: "subcategory"},
                                                {label: "Numar elevi", id: "students", numeric: true,},
                                                {label: "Scor mediu", id: "avg", numeric: true,},
                                                {label: "Min", id: "min", numeric: true,},
                                                {label: "Q1 (25%)", id: "q1", numeric: true,},
                                                {label: "Q2 (50%)", id: "q2", numeric: true,},
                                                {label: "Q3 (75%)", id: "q3", numeric: true,},
                                                {label: "Max", id: "max", numeric: true,},
                                            ],
                                            rows: table6,
                                        }
                                    } />
                                </div>
                                <div className="table-subsection">
                                    <p className="table-title">Graficul 4.{index + 1}. Boxplot cu distributia cvartilelor de performanta pentru {data.subject}, clasa {table6[index].grade}
                                        <TableTooltip type="boxplot"/>
                                    </p>
                                    <ScrollBar forceVisible="x" autoHide={false}  >
                                        <div id={`gradeBoxplot${index}`} style={{minWidth: "600px", maxWidth: "762px"}}>
                                            <CustomBoxplotChart
                                                series={[
                                                    {
                                                        name: "",
                                                        data: table6.map((data: any) => {
                                                            return [data.min, data.q1, data.q2, data.q3, data.max]})
                                                    }
                                                ]}
                                                xAxis={{
                                                    categories:
                                                        table6.map((data: any) => {
                                                            return data.subcategory.substring(0,40)
                                                        }),
                                                    title: {
                                                        text: 'Dimensiuni ale programei'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </ScrollBar>
                                </div>
                                <div className='mb-13'></div>
                            </div>
                        ))}
                        <h1>3.2. Distribuția elevilor în categorii decile naționale pentru fiecare clasa</h1>
                        {data.payload.table7.slice().reverse().map((table7: any, index: number) => (
                            <div key={index}>
                                <h1 className='subtitleH1 mb-13'>3.2.{index + 1}. Distribuția elevilor în categorii decile naționale pentru clasa {table7[index].grade}</h1>
                                <div className="table-subsection">
                                    <p className="table-title">Tabelul 6.{index + 1}. Numarul de elevi din clasa {table7[index].grade} plasati in fiecare decila nationala, pentru {data.subject}
                                        <TableTooltip type="decile"/>
                                    </p>
                                    <CustomTableBlack data={
                                        {
                                            columns: [
                                                {label: "Dimensiune", id: "subcategory"},
                                                {label: "Decila 1 [0,10]", id: "d1", numeric: true},
                                                {label: "Decila 2 (10,20]", id: "d2", numeric: true},
                                                {label: "Decila 3 (20,30]", id: "d3", numeric: true},
                                                {label: "Decila 4 (30,40]", id: "d4", numeric: true},
                                                {label: "Decila 5 (40,50]", id: "d5", numeric: true},
                                                {label: "Decila 6 (50,60]", id: "d6", numeric: true},
                                                {label: "Decila 7 (60,70]", id: "d7", numeric: true},
                                                {label: "Decila 8 (70,80]", id: "d8", numeric: true},
                                                {label: "Decila 9 (80,90]", id: "d9", numeric: true},
                                                {label: "Decila 10 (90,100]", id: "d10", numeric: true},
                                            ],
                                            rows: table7,
                                        }
                                    } />
                                </div>
                                <div className="table-subsection">
                                    <p className="table-title">Graficul 5.{index + 1}. Heatmap cu distributia decilelor de performanta pentru {data.subject}, clasa {table7[index].grade}
                                        <TableTooltip type="heatmap"/>
                                    </p>
                                    <ScrollBar forceVisible="x" autoHide={false}  >
                                        <div id={`gradeHeatmap${index}`} style={{width: "762px"}}>
                                            <CustomHeatmap
                                                series={[
                                                    {
                                                        name: '',
                                                        borderWidth: 0.1,
                                                        borderColor: "#888888",
                                                        data: heatMapData(table7),
                                                    },
                                                ]}
                                                yAxis={{
                                                    labels: {
                                                        allowOverlap: true,
                                                        style: {
                                                            wordBreak: 'break-all',
                                                            textOverflow: 'allow',
                                                            width: 150
                                                        }
                                                    },
                                                    categories:table7.map((data: any) => {
                                                        return data.subcategory.substring(0, 40)
                                                    }),
                                                    title: {
                                                        text: 'Dimensiuni ale programei'
                                                    },
                                                    reversed: true,
                                                }}
                                                xAxis={{
                                                    categories: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10'],
                                                    title: {
                                                        text: 'Decile'
                                                    }
                                                }}
                                                colorAxis={{
                                                    min: 0,
                                                    minColor: '#f5f4f9',
                                                    maxColor: '#7468ad',
                                                }}
                                            />
                                        </div>
                                    </ScrollBar>
                                </div>
                                <div className='mb-13'></div>
                            </div>
                        ))}
                        <h1>3.3. Distribuția elevilor în categorii de risc pentru fiecare clasa</h1>
                        {data.payload.table9.slice().reverse().map((table9: any, index: number) => (
                            <div key={index}>
                                <h1 className='subtitleH1 mb-13'>3.3.{index + 1}. Distribuția elevilor în categorii de risc pentru clasa {table9[index].grade}</h1>
                                <div className="table-subsection">
                                    <p className="table-title">Tabelul 7.{index + 1}. Numarul de elevi din clasa {table9[index].grade} plasati in fiecare decila nationala, pentru {data.subject}
                                        <TableTooltip type="risc"/>
                                    </p>
                                    <CustomTableBlack
                                        data={{
                                            columns: [
                                                {label: "Dimensiune", id: "subcategory"},
                                                {label: "A  ", id: "a", numeric: true},
                                                {label: "B  ", id: "b", numeric: true},
                                                {label: "C  ", id: "c", numeric: true},
                                                {label: "D  ", id: "d", numeric: true},
                                                {label: "E  ", id: "e", numeric: true},
                                                {label: "TOT  ", id: "total", numeric: true},
                                                {label: "A%  ", id: "a%", numeric: true},
                                                {label: "B%  ", id: "b%", numeric: true},
                                                {label: "C%  ", id: "c%", numeric: true},
                                                {label: "D%  ", id: "d%", numeric: true},
                                                {label: "E%  ", id: "e%", numeric: true},
                                            ],
                                            rows: table9,
                                        }}
                                        extraPadding="6px 12px"
                                    />
                                </div>
                                <div className="table-subsection mb-13">
                                    <p className="table-title">Graficul 6.{index + 1}. Heatmap al distribuției în funcție de scorurile generale pentru materia claselor testate
                                        <TableTooltip type="riscHeatmap"/>
                                    </p>
                                    <ScrollBar forceVisible="x" autoHide={false}  >
                                        <div id={`gradeRiscHeatmap${index}`} style={{width: "762px"}}>
                                            <CustomHeatmap
                                                series={[
                                                    {
                                                        name: '',
                                                        borderWidth: 0.1,
                                                        borderColor: "#888888",
                                                        data: heatMapData(table9, "risc")
                                                    },
                                                ]}
                                                yAxis={{
                                                    labels: {
                                                        allowOverlap: true,
                                                        style: {
                                                            wordBreak: 'break-all',
                                                            textOverflow: 'allow',
                                                            width: 150
                                                        }
                                                    },
                                                    categories:table9.map((data: any) => {
                                                        return data.subcategory.substring(0, 40)
                                                    }),
                                                    title: {
                                                        text: 'Dimensiuni ale programei'
                                                    },
                                                    reversed: true,
                                                }}
                                                xAxis={{
                                                    categories: ['A', 'B', 'C', 'D', 'E'],
                                                    title: {
                                                        text: 'Categorii de risc'
                                                    }
                                                }}
                                                colorAxis={{
                                                    min: 0,
                                                    minColor: '#fcf3ee',
                                                    maxColor: '#de6525',
                                                }}
                                            />
                                        </div>
                                    </ScrollBar>
                                </div>
                                <div className='mb-13'></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Report;