import Grid from "@mui/material/Grid";
import FormLabelBox from "../ui/FormLabelBox";
import FormFieldBox from "../ui/FormFieldBox";
import CustomDatePicker from "./CustomDatePicker";
import FormField from "./FormField";
import CustomButton from "../ui/CustomButton";
import {FormProvider} from "react-hook-form";
import {ISearchForm} from "../../pages/SchoolReportGenerate";
import {PaperStyled} from "./AutocompleteAPI";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import {AutocompleteStyled} from "./DashboardFilters";

const SchoolReportForm = ({
                    startDate,
                    endDate,
                    onDateRangeChange,
                    onSubmit,
                    tags,
                    tag,
                    methods,
                } : ISearchForm) => {

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={1.5} alignItems="center">
                    <FormLabelBox title="Materie:" />

                    <FormFieldBox>
                        <FormField
                            name="subject"
                            type="select"
                            label="Alege materia"
                            defaultValue=""
                            options={[
                                { label: "Istorie", value: "22"},
                                { label: "Matematică", value: "1"},
                                { label: "Română", value: "4"},
                                {label: "Bac Mate/Științe", value: "21"},
                                {label: "Bac Mate-Info", value: "212"},
                                {label: "Bac Română", value: "213"},
                                {label: "Evaluare nationala romana", value: "20"},
                                {label: "Evaluare nationala matematica", value: "201"},
                                {label: "Literație", value: "19"},
                                {label: "Literație digitală", value: "24"},
                            ]}
                            errorMessage={methods.formState.errors.subject?.message}
                            error={!!methods.formState.errors.subject?.message}
                        />
                    </FormFieldBox>

                    <FormLabelBox title="Tag:" />

                    <FormFieldBox>
                        <FormField
                            name="tag"
                            type="autocomplete"
                            label="Alege tagul"
                            multiple={true}
                            defaultValue={tag}
                            options={tags.map((item: string) => {
                                return {
                                    label: item,
                                    value: item,
                                };
                            })}
                            errorMessage={methods.formState.errors.tag?.message}
                            disableClearable={true}
                            error={!!methods.formState.errors.tag?.message}
                            limitTags={4}
                        />
                    </FormFieldBox>

                    <FormLabelBox title="Perioadă:" />

                    <FormFieldBox>
                            <CustomDatePicker
                                name="interval"
                                startDate={startDate}
                                endDate={endDate}
                                onDateChange={onDateRangeChange}
                                type="dateRangePicker"
                                small={false}
                                errorMessage={methods.formState.errors.interval?.message}
                                error={!!methods.formState.errors.interval?.message}
                            />
                    </FormFieldBox>

                    <FormLabelBox />

                    <FormFieldBox>
                        <CustomButton
                            bgcolor="dark"
                            submit
                            buttonType="textIcon"
                        >
                            Generează Raport
                        </CustomButton>
                    </FormFieldBox>
                </Grid>
            </form>
        </FormProvider>
    )
}

export default SchoolReportForm;