import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import PageTitle from "../components/ui/PageTitle";
import BoxContent from "../components/ui/BoxContent";
import GenericButton from "../components/ui/GenericButton";
import CustomDataTable, {Order} from "../components/ui/CustomDataTable";
import {useNavigate} from "react-router-dom";
import {APIDeleteReport, APIGetReports} from "../api/pages/schoolReports";
import {useAuth} from "../hooks/useAuth";
import moment from "moment/moment";
import Loading from "../components/ui/Loading";

const SchoolReports = () => {
    const { update } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [exportedFiles, setExportedFiles] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("name");

    useEffect(() => {
        setLoading(true);
        APIGetReports({page: `?page=${page + 1}`})
            .then((response) => {
                if (response.data.success) {
                    console.log("APIGetReports OK", response);
                    setExportedFiles(response.data.data.data);
                    setTotalRows(response.data.data.total);
                    setPerPage(response.data.data.per_page);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getClassroom ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setExportedFiles([]);
        setPage(newPage);
    };

    const deleteReport = (id: string, callback: Function) => {
        update("loading", { show: true, modal: true });

        APIDeleteReport({ id })
            .then((response) => {
                if (response.data.success) {
                    console.log("APIDeleteReport OK", response);
                    update("loading", { show: false });
                    setExportedFiles(exportedFiles.filter((item) => item.id.toString() !== id.toString()));
                    setTotalRows(prevState => prevState - 1);
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
                callback();
            })
            .catch((err) => {
                console.log("APIDeleteReport ERR", err);
                update("loading", { show: false });
                update("error", { show: true, code: "A001" });
            });
    };

    const confirmDelete = (report: {
        id: number;
        [key: string]: any;
    }) => {
        update("confirm", {
            show: true,
            closable: true,
            title: "Ești sigur că vrei să ștergi acest raport?",
            message: (
                <>
                    Acțiunea va șterge definitiv raportul de
                    <strong> {report.subject}, {report.tag}</strong> creat la <strong>{moment(report.created_at).locale("ro").format("DD.MM.YYYY")}</strong>!
                </>
            ),
            returnFunction: (callback: Function) => {
                deleteReport(report.id.toString(), callback);
            },
            buttonYes: "Șterge",
            buttonNo: "Închide",
        });
    };

    return (
        <Box component="main">
            <PageTitle
                actions={
                    <GenericButton
                        type="export"
                        onClick={() => {
                            navigate("/rapoarte/genereaza");
                        }}
                    />
            }>
              Rapoarte
            </PageTitle>
            <BoxContent>
                <Box width="100%">
                    {loading ? (
                            <Loading show={true} />
                        ) : exportedFiles.length === 0 ? (
                            <Box>Niciun raport generat.</Box>
                        ) : (
                            <>
                                <CustomDataTable
                                    columns={[
                                        {
                                            id: "tag",
                                            label: "Tag",
                                            numeric: false,
                                            disablePadding: false,
                                            sortable: false,
                                            noWrap: true,
                                        },
                                        {
                                            id: "subject",
                                            label: "Materie",
                                            numeric: false,
                                            disablePadding: false,
                                            sortable: false,
                                        },
                                        // {
                                        //     id: "date",
                                        //     label: "Data",
                                        //     numeric: false,
                                        //     disablePadding: false,
                                        //     sortable: false,
                                        // },
                                        {
                                            id: "created_at",
                                            label: "Creat la",
                                            numeric: false,
                                            disablePadding: false,
                                            sortable: false,
                                        },
                                        {
                                            id: "actions",
                                            label: "Acțiuni",
                                            numeric: true,
                                            disablePadding: false,
                                            sortable: false,
                                        }
                                    ]}
                                    rows={exportedFiles.map((exportedFile) => {
                                        return {
                                            tag: exportedFile.tag.length === 0 ? "-" : exportedFile.tag.map((item: string, index: number) => (
                                                <Box key={index} mr={1}>{item}</Box>
                                            )),
                                            subject: exportedFile.subject,
                                            // date: `${moment(exportedFile.start_date).locale("ro").format("DD.MM.YYYY")} - ${moment(exportedFile.end_date).locale("ro").format("DD.MM.YYYY")}`,
                                            created_at:moment(exportedFile.created_at).locale("ro").format("DD.MM.YYYY"),
                                            actions: (
                                                <Box display="flex" justifyContent="end">
                                                    <Box mr={1}>
                                                        <GenericButton
                                                            type="report"
                                                            onClick={() => {
                                                                window.open(`/rapoarte/${exportedFile.id}`);
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <GenericButton type="delete" onClick={() => confirmDelete(exportedFile)} />
                                                    </Box>
                                                </Box>

                                            ),
                                        };
                                    })}
                                    rowsPerPage={perPage}
                                    page={page}
                                    totalRows={totalRows}
                                    onPageChange={handleChangePage}
                                    orderBy={orderBy}
                                    order={order}
                                />
                            </>
                        )}
                </Box>
            </BoxContent>

        </Box>
    );
};

export default SchoolReports;
