import {styles} from "../../StylesPDFSchoolReport";
import {Image, Text, View} from "@react-pdf/renderer";
import React from "react";

const Section31 = ({subject, table6, imagesBoxplot}: any) => {
    const tableRows=[
            {name: "Dimensiune", id: "subcategory"},
            {name: "Numar elevi", id: "students"},
            {name: "Scor mediu", id: "avg"},
            {name: "Min", id: "min"},
            {name: "Q1 (25%)", id: "q1"},
            {name: "Q2 (50%)", id: "q2"},
            {name: "Q3 (75%)", id: "q3"},
            {name: "Max", id: "max"},
        ];

    return (
        <>
            {table6.slice().reverse().map((items: any, index: number) => (
                <View key={index}>
                    <Text style={[styles.h2, styles.mb13]}>3.1.{index + 1}. Scoruri pentru clasa {items[index].grade}</Text>
                    <View style={styles.tableSubsection} wrap={false} key={1}>
                        <Text style={styles.tableTitle}>Tabelul 5.{index + 1}. Scorurile totale obtinute pentru {subject}, clasa {items[index].grade}</Text>
                        <View style={styles.chartTable}>
                            <View style={styles.gradesRowHeader}>
                                {tableRows.map((row, index: number) => (
                                    <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "max" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                                ))}
                            </View>
                            {items.map((item: any, index: any) => (
                                <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                                    {tableRows.map((row, index: number) => (
                                        <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "max" ? styles.colValuesEnd : styles.colValues ]} key={index}>{typeof item[row.id] === 'string' ? item[row.id].substring(0, 40) : item[row.id]}</Text>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                   <View style={styles.tableSubsection} wrap={false} key={2}>
                       <Text style={styles.tableTitle}>Graficul 4.{index + 1}. Boxplot cu distributia cvartilelor de performanta pentru {subject}, clasa {items[index].grade}</Text>
                       {imagesBoxplot.map((image : any) => (
                               <>
                                   {image.id === `gradeBoxplot${index}` && <Image src={image.image} key={index}/>}
                               </>
                           )
                       )}
                   </View>
                </View>
            ))}
        </>
    )
}

export default Section31;