import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import { useIdleTimer } from 'react-idle-timer';

const AppLogout = ({children} : any) => {
    const [remaining, setRemaining] = useState<number>(0);
    const { logout, user, update } = useAuth();

    const onIdle = () => {
        logout();
    };

    const {
        getRemainingTime,
    } = useIdleTimer({
        onIdle,
        timeout: 7200000,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200
    });

    // const isIddle = () => {
    //     let currentDate = new Date();
    //     let userLoginDate = new Date(user.date);
    //     let difference = currentDate.getTime() - userLoginDate.getTime();
    //     let hoursMill = 2000 * 60 * 60;
    //     if(Math.abs(difference) > hoursMill) {
    //         logout();
    //     }
    // }
    //
    // useEffect(() => {
    //     isIddle();
    // }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
            // update("user", {date: new Date()});
        }, 500)

        return () => {
            clearInterval(interval)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
}

export default AppLogout;