import {Document, Page, View, Font, Text, Image} from "@react-pdf/renderer";

import RubikLight from "../../../../fonts/Rubik-Light.ttf";
import RubikRegular from "../../../../fonts/Rubik-Regular.ttf";
import RubikMedium from "../../../../fonts/Rubik-Medium.ttf";
import RubikBold from "../../../../fonts/Rubik-Bold.ttf";
import RubikBlack from "../../../../fonts/Rubik-Black.ttf";

import {ReportLeftBottom, ReportSegmentsImage} from "../../../../utils/reportsFunctions";
import {styles } from "../../StylesPdfReport";
import moment from "moment";
import CoverPage from "../../components/pdf/CoverPage";
import AboutSection from "./AboutSection";
import Chart from "../../components/pdf/Chart";
import ChartSubsection from "../../components/pdf/ChartSubsection";
import Units from "../../components/pdf/Units";
import MarkGroup from "../../components/pdf/MarkGroup";
import TableRow from "../../components/pdf/TableRow";
import ChartNoteWrapperBig from "../../components/pdf/ChartNoteWrapperBig";
import SubcategorySection from "../../components/pdf/SubcategorySection";
import {capitalize} from "@mui/material/utils";

Font.register({
    family: "Rubik",
    format: "truetype",
    fonts: [
        { src: RubikLight, fontWeight: 300 },
        { src: RubikRegular, fontWeight: 400 },
        { src: RubikMedium, fontWeight: 500 },
        { src: RubikBold, fontWeight: 700 },
        { src: RubikBlack, fontWeight: 800 },
    ],
});

const Report = ({ reportData, gradeLevels, graphData, skillsData }: any) => {
    const left = ReportLeftBottom(reportData.score, "school")[0];
    const bottom = ReportLeftBottom(reportData.score, "school")[1];
    const segmentsImage = ReportSegmentsImage(reportData.score, "school");
    let coverImage = "/images/reports/cover-istorie.png";

    if (reportData.type === "istorie") coverImage="/images/reports/cover-istorie.png";
    else if((reportData.type === "matematica" ||
        reportData.type === "bac-matematica" ||
        reportData.type === "evaluare-matematica") ) coverImage = "/images/reports/cover-img.png";
    else if(reportData.type === "romana" ||
        reportData.type === "evaluare-romana" ||
        reportData.type === "bac-romana") coverImage = "/images/reports/cover-romana.png"

    return (
        <Document>
            <Page wrap size="A4" style={styles.body}>
                <View style={styles.coverBg}>
                    <CoverPage coverImage={coverImage} type="school">
                        <View style={styles.titleCover}>
                            <Text style={styles.h1}>Raport de evaluare</Text>
                            <Text style={styles.h2}>Sesiune #{reportData.test.id}</Text>
                            <Text style={styles.h1}>Scor {reportData.score}</Text>
                            <Text style={styles.h2}>
                                {reportData.test.title !== null
                                    ? capitalize(reportData.test.title)
                                    : reportData.test_mode.id < 3
                                        ? capitalize(reportData.type)
                                        : ""}
                                {" "}
                                {reportData.test_mode.id < 3 && (reportData.test.grade.name === "I" || reportData.test.grade.name === "0" ? reportData.test.grade.name : `clasa a ${reportData.test.grade.name}-a`)}
                                {reportData.test_mode.id >= 3 && reportData.test.grade.name} |{" "}
                                {moment(reportData.test.started_at).locale("ro").format("DD MMMM YYYY")}
                            </Text>
                        </View>
                    </CoverPage>
                    <View style={styles.wrapperCover}>
                        <AboutSection type={reportData.type}/>
                        <View break style={styles.sectionAbout}>
                            <Text style={styles.h1}>Despre Scorul BRIO</Text>
                            <Chart
                                left={left}
                                bottom={bottom}
                                testScore={reportData.score}
                                segmentsImage={segmentsImage}
                                big={true}
                                type="school"
                            />
                            {gradeLevels.map((gradeLevel: any, index: any) => (
                                <View key={index}>
                                    <ChartSubsection small={false} gradeLevel={gradeLevel} score={reportData.score} index={index} type="school" />
                                </View>
                            ))}
                        </View>
                        {graphData.length > 0 &&
                            <>
                                <View break style={styles.sectionAbout}>
                                    <Text style={styles.h1}>Profilul competențelor evaluate</Text>
                                    <View style={styles.tableNote}>
                                        <View style={{ position: "relative" }}>
                                            <MarkGroup type="school" />
                                            <View style={styles.gradesRow}>
                                                <View style={styles.colChapter}></View>
                                                <View style={[styles.colValues, { paddingBottom: "20pt" }]}>
                                                    <Units type="school"/>
                                                </View>
                                            </View>
                                            {graphData.map((section: any, index: any) => (
                                                <View key={index} >
                                                    <TableRow section={section} index={index} />
                                                </View>
                                            ))}
                                        </View>
                                        <View style={[styles.gradesRow, { border: "none", marginTop: "7pt" }]}>
                                            <View style={styles.colChapter}></View>
                                            <View style={styles.colValues}>
                                                <View>
                                                    <Image src="/images/reports/ld/chart-tabel.png" style={styles.chartTable} />
                                                    <Units type="school"/>
                                                    <ChartNoteWrapperBig big={false} type="school" />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </>
                        }
                        <View style={styles.sectionAbout}>
                            {skillsData.length > 0 && (skillsData.map((skillData: any, index: any) => (
                                    <View key={index}>
                                        <SubcategorySection skillsData={skillData} key={index} type="school" small={false}/>
                                    </View>
                                ))
                            )}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Report;
