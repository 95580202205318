import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { APIGetActiveTests } from "api/pages/tests";
import Loading from "components/ui/Loading";
import CustomDataTable, { Order } from "components/ui/CustomDataTable";
import SearchForm, { IFormInputs } from "components/form/SearchForm";
import { SubmitHandler } from "react-hook-form";
import moment from "moment";
import {
  activeTestsPrincipalsTableColumns,
  activeTestsStudentsTableColumns,
  activeTestsTeachersTableColumns,
} from "utils/testsTableColumns";
import GenericButton from "components/ui/GenericButton";
import {
    activeBacTestsPrincipalsTableColumns, activeBacTestsStudentsTableColumns,
    activeBacTestsTeachersTableColumns
} from "../../utils/testsBacEvaluateTableColumns";
interface Props {
  type: string;
}

const TestsActive = ({ type }: Props) => {
  const { update, user } = useAuth();
  const navigate = useNavigate();

  const [activeTestsTableColumns, setActiveTestsTableColumns] = useState<any[]>([]);
  const [activeTestsLoading, setActiveTestsLoading] = useState<boolean>(false);
  const [activeTests, setActiveTests] = useState<any[]>([]);
  const [activeTestsTotalRows, setActiveTestsTotalRows] = useState<number>(0);
  const [activeTestsPerPage, setActiveTestsPerPage] = useState<number>(15);
  const [activeTestsPage, setActiveTestsPage] = useState<number>(0);
  const [activeTestsOrder, setActiveTestsOrder] = useState<Order>("desc");
  const [activeTestsOrderBy, setActiveTestsOrderBy] = useState<string>("test");
  const [activeTestsQuery, setActiveTestsQuery] = useState<string>("");
  const [activeTestsSearch, setActiveTestsSearch] = useState<boolean>(false);

  useEffect(() => {
    setActiveTestsLoading(true);
    APIGetActiveTests({
      page: `?page=${activeTestsPage + 1}`,
      sort: activeTestsOrderBy,
      order: activeTestsOrder,
      search: activeTestsQuery,
      type: type,
    })
      .then((response) => {
        if (response.data.success) {
          // console.log("getActiveTests OK", response);
            if(type === "bac" || type === "evaluate") {
                setActiveTests(
                    response.data.data.data.map(
                        (test: {
                            test: string;
                            test_type: string;
                            test_tag: string;
                            test_grade: string;
                            owner_id: number;
                            owner_name: string;
                            user_id: number;
                            user_name: string;
                            classroom_id: number;
                            classroom_name: string;
                            test_is_open: string;
                            test_created_at: string;
                            test_expires_at: string;
                        }) => {
                            if (user.position === "principal") {
                                return {
                                    test: test.test,
                                    test_type: test.test_grade,
                                    test_tag: test.test_tag,
                                    owner_name: <Link to={`/profesori/${test.owner_id}`}>{test.owner_name}</Link>,
                                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_name}</Link>,
                                    test_is_open: test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_expires_at: moment(test.test_expires_at).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end">
                                            <Box>
                                                <GenericButton
                                                    type="items"
                                                    onClick={() => {
                                                        if (type === "bac") {
                                                            window.open(`/teste/previzualizare/${test.test}`);
                                                        } else if (type === "evaluate") {
                                                            window.open(`/teste-evaluare/previzualizare/${test.test}`);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ),
                                };
                            } else if (user.position === "teacher") {
                                return {
                                    test: test.test,
                                    test_type: test.test_grade,
                                    test_tag: test.test_tag,
                                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_name}</Link>,
                                    status: test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_expires_at: moment(test.test_expires_at).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end">
                                            <Box>
                                                <GenericButton
                                                    type="items"
                                                    onClick={() => {
                                                        if (type === "bac") {
                                                            window.open(`/teste/previzualizare/${test.test}`);
                                                        } else if (type === "evaluate") {
                                                            window.open(`/teste-evaluare/previzualizare/${test.test}`);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ),
                                };
                            } else if (user.position === "student") {
                                return {
                                    test: test.test,
                                    test_type: test.test_grade,
                                    owner_name: test.owner_name,
                                    classroom_name: test.classroom_name,
                                    status: test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_expires_at: moment(test.test_expires_at).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end" width="100%">
                                            <GenericButton
                                                type="start"
                                                onClick={() => {
                                                    navigate(`/teste/${test.test}`);
                                                }}
                                            />
                                        </Box>
                                    ),
                                };
                            }
                            return {};
                        },
                    ),
                );
            } else {
                setActiveTests(
                    response.data.data.data.map(
                        (test: {
                            test: string;
                            test_type: string;
                            test_tag: string;
                            test_grade: string;
                            owner_id: number;
                            owner_name: string;
                            user_id: number;
                            user_name: string;
                            classroom_id: number;
                            classroom_name: string;
                            test_is_open: string;
                            test_created_at: string;
                            test_starts_at: Date;
                            test_closing_date: string;
                        }) => {
                            const testStartsAt = moment(test.test_starts_at);
                            const currentDate = moment(new Date(Date.now()));
                            if (user.position === "principal") {
                                return {
                                    test: test.test,
                                    test_type: test.test_type,
                                    test_tag: test.test_tag,
                                    test_grade: test.test_grade,
                                    owner_name: <Link to={`/profesori/${test.owner_id}`}>{test.owner_name}</Link>,
                                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_name}</Link>,
                                    test_is_open: testStartsAt.isAfter(currentDate) ? `Poate fi accesat la ${test.test_starts_at}` : test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_closing_date: moment(test.test_closing_date).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end">
                                            <Box>
                                                <GenericButton
                                                    type="items"
                                                    onClick={() => {
                                                        if (type === "school") {
                                                            window.open(`/teste/previzualizare/${test.test}`);
                                                        } else if (type === "training") {
                                                            window.open(`/teste-antrenament/previzualizare/${test.test}`);
                                                        } else if (type === "literacy") {
                                                            window.open(`/teste-literatie/previzualizare/${test.test}`);
                                                        } else if (type === "digitalLiteracy") {
                                                            window.open(`/teste-literatie-digitala/previzualizare/${test.test}`);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ),
                                };
                            } else if (user.position === "teacher") {
                                return {
                                    test: test.test,
                                    test_type: test.test_type,
                                    test_tag: test.test_tag,
                                    test_grade: test.test_grade,
                                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_name}</Link>,
                                    test_is_open: testStartsAt.isAfter(currentDate) ? `Poate fi accesat la ${moment(test.test_starts_at).locale("ro").format("DD.MM.YYYY, HH:mm")}` : test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_closing_date: moment(test.test_closing_date).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end">
                                            <Box>
                                                <GenericButton
                                                    type="items"
                                                    onClick={() => {
                                                        if (type === "school") {
                                                            window.open(`/teste/previzualizare/${test.test}`);
                                                        } else if (type === "training") {
                                                            window.open(`/teste-antrenament/previzualizare/${test.test}`);
                                                        } else if (type === "literacy") {
                                                            window.open(`/teste-literatie/previzualizare/${test.test}`);
                                                        } else if (type === "digitalLiteracy") {
                                                            window.open(`/teste-literatie-digitala/previzualizare/${test.test}`);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ),
                                };
                            } else if (user.position === "student") {
                                return {
                                    test: test.test,
                                    test_type: test.test_type,
                                    test_grade: test.test_grade,
                                    owner_name: test.owner_name,
                                    classroom_name: test.classroom_name,
                                    status: testStartsAt.isAfter(currentDate) ? `Poate fi accesat la ${moment(test.test_starts_at).locale("ro").format("HH:mm, DD.MM.YYYY")}` : test.test_is_open ? "Deschis" : "Nedeschis",
                                    test_created_at: moment(test.test_created_at).locale("ro").format("DD.MM.YYYY"),
                                    test_closing_date: moment(test.test_closing_date).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                    actions: (
                                        <Box display="flex" justifyContent="end" width="100%">
                                            <GenericButton
                                                disabled={testStartsAt.isAfter(currentDate)}
                                                type="start"
                                                onClick={() => {
                                                    navigate(`/teste/${test.test}`);
                                                }}
                                            />
                                        </Box>
                                    ),
                                };
                            }
                            return {};
                        },
                    ),
                );
            }
          setActiveTestsTotalRows(response.data.data.total);
          setActiveTestsPerPage(response.data.data.per_page);
          setActiveTestsLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getActiveTests ERR", err);
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTestsPage, activeTestsOrderBy, activeTestsOrder, activeTestsQuery, activeTestsSearch]);

  useEffect(() => {
      if(type === "bac" || type === "evaluate") {
          if (user.position === "principal") {
              setActiveTestsTableColumns(activeBacTestsPrincipalsTableColumns);
          } else if (user.position === "teacher") {
              setActiveTestsTableColumns(activeBacTestsTeachersTableColumns);
          } else if (user.position === "student") {
              setActiveTestsTableColumns(activeBacTestsStudentsTableColumns);
          }
      } else {
          if (user.position === "principal") {
              setActiveTestsTableColumns(activeTestsPrincipalsTableColumns);
          } else if (user.position === "teacher") {
              setActiveTestsTableColumns(activeTestsTeachersTableColumns);
          } else if (user.position === "student") {
              setActiveTestsTableColumns(activeTestsStudentsTableColumns);
          }
      }
  }, [user]);

  const handleActiveTestsChangePage = (event: unknown, newPage: number) => {
    setActiveTests([]);
    setActiveTestsPage(newPage);
  };

  const handleActiveTestsRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = activeTestsOrderBy === property && activeTestsOrder === "asc";
    setActiveTestsOrder(isAsc ? "desc" : "asc");
    setActiveTestsOrderBy(property);
    setActiveTestsPage(0);
  };

  const handleActiveTestsSearch: SubmitHandler<IFormInputs> = (data) => {
    setActiveTestsQuery(data.keywords);
    setActiveTestsPage(0);
    setActiveTestsSearch(true);
  };

  const handleActiveTestsClearSearch = () => {
    setActiveTestsQuery("");
    setActiveTestsSearch(false);
    setActiveTestsPage(0);
  };

  return (
    <>
      <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
        <SearchForm
          onSubmit={handleActiveTestsSearch}
          onChange={(e) => setActiveTestsQuery(e.target.value)}
          onClick={handleActiveTestsClearSearch}
          query={activeTestsQuery}
        />
      </Box>
      {activeTestsLoading ? (
        <Loading show={true} />
      ) : activeTests.length === 0 ? (
        <Box>Niciun test activ.</Box>
      ) : (
        <CustomDataTable
          columns={activeTestsTableColumns}
          rows={activeTests}
          rowsPerPage={activeTestsPerPage}
          page={activeTestsPage}
          totalRows={activeTestsTotalRows}
          onPageChange={handleActiveTestsChangePage}
          handleRequestSort={handleActiveTestsRequestSort}
          orderBy={activeTestsOrderBy}
          order={activeTestsOrder}
        />
      )}
    </>
  );
};

export default TestsActive;
